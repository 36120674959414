<template>
  <v-snackbar
    v-model="isVisible"
    color="info_light"
    multi-line
    vertical
  >
    <v-icon
      class="mr-2"
      color="info"
    >
      $information
    </v-icon>
    <span class="black--text">{{ $trans('newsletter_email_is_used') }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        color="success2"
        text
        @click="resend"
        :loading="isLoading"
      >
        {{ $trans('newsletter_send_again') }}
      </v-btn>
      <v-btn
        v-bind="attrs"
        text
        color="black"
        @click="$emit('close')"
      >
        {{ $trans('cancel') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import { mapActions } from 'vuex'
import { errorNotification, successNotification } from '@/calendesk/prototypes/notifications'

export default mixins(DraftElement).extend({
  name: 'ResendActivationLink',
  props: {
    email: {
      type: String
    }
  },
  data () {
    return {
      isVisible: true,
      isLoading: false
    }
  },
  watch: {
    isVisible (value) {
      this.$emit('close', value)
    }
  },
  methods: {
    ...mapActions({
      resendNewsletterActivationLink: 'user/resendNewsletterActivationLink'
    }),
    resend () {
      this.isLoading = true
      this.resendNewsletterActivationLink(this.email).then(() => {
        successNotification('newsletter_send_again_success')
      }).catch((error) => {
        errorNotification('send_again_fail', error, false)
      }).finally(() => {
        this.isLoading = false
        this.isVisible = false
      })
    }
  }
})
</script>

import mixins from 'vue-typed-mixins'
import Section from '@/calendesk/sections/section/mixins/Section'
import DefaultButton from '@/calendesk/sections/section/components/DefaultButton.vue'
import { mapActions } from 'vuex'
import { errorNotification, successNotification } from '@/calendesk/prototypes/notifications'
import { email, maxChars, required } from '@/calendesk/forms/validators'
import ResendActivationLink from '@/calendesk/sections/section/newsletter/components/ResendActivationLink.vue'

export default mixins(Section).extend({
  components: { DefaultButton, ResendActivationLink },
  data () {
    return {
      email: null,
      valid: null,
      isLoading: false,
      showResendLinkSnackbar: false,
      rules: {
        required,
        email,
        maxChars
      }
    }
  },
  computed: {

  },
  methods: {
    ...mapActions({
      newsletterSignup: 'user/newsletterSignup'
    }),
    handleSubscribeAction () {
      if (this.valid) {
        this.isLoading = true
        this.newsletterSignup(this.email).then(() => {
          successNotification(this.data.configuration.wb_newsletter_success_message__long_text__, true)
        }).catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 422:
                this.showResendLinkSnackbar = true
                break
              default:
                errorNotification(null, error)
                break
            }
          } else {
            errorNotification(null, error)
          }
        }).finally(() => {
          this.isLoading = false
        })
      } else {
        errorNotification('email_is_incorrect', null, false)
      }
    }
  }
})
